body {
    background-color: rgb(32, 33, 36);
}

.Logo-container {
    padding-top: 10vmin;
    padding-bottom: 5vmin;
}

.App-logo {
    width: 40vmin;
    pointer-events: none;
}


.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

.App-header h1 {
    font-weight: 600;
    font-size: calc(10px + 4vmin);
    line-height: calc(10px + 4.2vmin);
    margin-bottom: 0;
}

.App-header h2 {
    font-weight: 400;
    font-size: calc(10px + 2vmin);
    line-height: calc(10px + 2.2vmin);
}

.App-link {
    color: #61dafb;
}